<template>
  <div class="leaderboard-wrap">
    <template v-if="leaderboard && leaderboard.length > 0">
      <ul class="leaderboard pvp">
        <li v-for="(rank, index) in leaderboard" :key="`leaderboard_${competition.id}_${index}`">
          <div class="user">
            <i>
              <span v-if="rank.rank == 1 && leaderboard.length > 1">{{$emoji(':first_place_medal:')}}</span>
              <span v-else>{{rank.rank}}</span>
            </i>
            <user-card v-bind:user="rank.user" />
          </div>
          <div class="score">
            {{$formatNumber(rank.points)}} {{`point${rank.points == 1 ? '' : 's'}`}}
          </div>
        </li>
      </ul>
    </template>
    <template v-else-if="leaderboard && leaderboard.length === 0">
      <div class="empty">No leaderboard rankings.</div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CompetitionLeaderboard',
  props: ['competition', 'leaderboard']
}
</script>

<style lang="scss">
ul.leaderboard {
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    padding: 10px 0;
    margin: 0;
    align-items: center;
    border-bottom: 1px solid rgb(246, 246, 246);

    .score {
      display: block;
      font-size: 12px;
      padding: 2px 10px;
      background: $light;
      color: $muted-text;
      border-radius: 100em;
      margin: 0 0 0 auto;
    }

    .user {
      display: block;
      position: relative;
      user-select: none;

      .group-name {
        display: flex;
        align-items: center;
        span {
          display: block;
          margin: 0 0 0 5px;
        }
      }

      > i {
        display: block;
        background: #fff;
        box-shadow: rgba(0,0,0,0.1) 0 2px 8px;
        font-style: normal;
        text-align: center;
        width: 22px;
        height: 22px;
        line-height: 21px;
        border-radius: 100%;
        position: absolute;
        z-index: 99;
        left: -10px;
        top: 7px;

        > span {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
