<template>
  <aside>
    <header>
      <h2>Competition Leaderboard</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <leaderboard v-if="leaderboard !== null" :competition="competition" :leaderboard="leaderboard" />
      <loading-indicator v-else />
    </main>
  </aside>
</template>

<script>
import Leaderboard from '@/components/Awards/Competitions/Leaderboard'

export default {
  props: ['data'],
  components: {
    Leaderboard
  },
  data() {
    return {
      competition: null,
      leaderboard: null
    }
  },
  created() {
    this.competition = _.cloneDeep(this.data)
    this.populateLeaderboard()
  },
  methods: {
    async populateLeaderboard() {
      const resp = await this.$api.Competitions.get_leaderboard(this.competition.id)
      this.leaderboard = resp;
    },
    close() {
      this.$emit('done')
    }
  }
}
</script>